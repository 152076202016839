import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 新建学生
export async function createStudent(params) {
  return request('/person-service/student_info/create_student_info', {
    method: 'post',
    data: params,
  });
}

// 获取学生信息
export async function getDetail(params) {
  return request('/person-service/student_info/student_info_detail', {
    method: 'get',
    data: params,
  });
}

// 获取简要学生信息
export async function getStudentInfo(params) {
  return request('/person-service/student_info/get_student_simple_info', {
    method: 'get',
    data: params,
  });
}

// 修改学生信息
export async function updateStudent(params) {
  return request('/person-service/student_info/update_student_info', {
    method: 'post',
    data: params,
  });
}

// 新建 followup
export async function createFollowup(params) {
  return request('/person-service/followup/create_followup', {
    method: 'post',
    data: params,
  });
}

// 获取线索来源
export async function getLeadsSourceTypeList(params) {
  return request('/person-service/leads_source_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 获取目的
export async function getContactPurposeTypeList(params) {
  return request('/person-service/contact_purpose_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 根据手机号查询家长
export async function getParentByPhone(params) {
  return request('/person-service/parent_info/get_parent_by_phone', {
    method: 'get',
    data: params,
  });
}

// 学员转为在学
export async function transfer_to_learning(params) {
  return request('/person-service/student_info/transfer_to_learning', {
    method: 'post',
    data: params,
  });
}

// 学员转为非在学
export async function transfer_to_not_learning(params) {
  return request('/person-service/student_info/transfer_to_not_learning', {
    method: 'post',
    data: params,
  });
}

// 获取学员配置
export async function get_student_config(params) {
  return request('/person-service/student_info/get_student_config', {
    method: 'get',
    data: params,
  });
}

// 设置是否接收课前提醒
export async function set_before_class_remind(params) {
  return request('/person-service/student_info/set_before_class_remind', {
    method: 'post',
    data: params,
  });
}

// 设置是否接收课时变更提醒
export async function set_classhour_change_remind(params) {
  return request('/person-service/student_info/set_classhour_change_remind', {
    method: 'post',
    data: params,
  });
}

// 设置是否接收课堂评价提醒
export async function set_class_evaluation_remind(params) {
  return request('/person-service/student_info/set_class_evaluation_remind', {
    method: 'post',
    data: params,
  });
}

// 设置是否接收课后作业提醒
export async function set_homework_remind(params) {
  return request('/person-service/student_info/set_homework_remind', {
    method: 'post',
    data: params,
  });
}

// 获取页面二维码
export async function getBindQrCode() {
  return request('/organization-service/organization/get_bind_qr_code_url', {
    method: 'get',
  });
}

// 取消回访
export async function cancel_revisit(params) {
  return request('/person-service/followup/cancel_revisit', {
    method: 'post',
    data: params,
  });
}

// 跟进线索
export async function follow(params) {
  return request('/person-service/follow_record/follow', {
    method: 'post',
    data: params,
  });
}

// 释放线索
export async function unfollow(params) {
  return request('/person-service/follow_record/unfollow', {
    method: 'post',
    data: params,
  });
}
